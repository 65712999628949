// APIリクエストを送る関数
async function sendApiRequest(endpoint, requestData, isFairyTale = false) {

  try {
    if (requestData.history && !isFairyTale) {
      requestData.user_input += "に変更";
    }
    let styleIncludePrompt = "";
    if (requestData.style && requestData.style.startsWith("custom-")) {
      styleIncludePrompt = requestData.style.replace("custom-", "");
      requestData.style = null;
    } else {
      styleIncludePrompt = requestData.style;
    }
    // styleパラメータのみでは効かない場合があるため、命令に挿入
    if (styleIncludePrompt) {
      requestData.user_input += ", Change to " + styleIncludePrompt + " style.";
    }

    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error, status: ${response.status}`);
    }
    const data = await response.json();
    if (data && data.generate_images.length > 0) {
      // saveToHistory(requestData.user_input, data.generate_prompt);
      // setImagesData(data.generate_images.map(item => item.base64)); // 画像データをマッピングして保持
      // setSeed(data.generate_prompt[0].seed); //seed値
      return data;
    }
  } catch (error) {
    console.error("Failed Request Error", error);
  }
}

// 童話生成のエンドポイントにリクエストする関数
export async function fetchTaleData(
  user_input,
  seed,
  style = null,
  history = null
) {
  let samples;
  const isFairyTale = true;
    const endpoint = 'https://app-it-fair2-gycteneha5d3beb9.japanwest-01.azurewebsites.net/generate-fairytale';
    // const endpoint = 'http://127.0.0.1:8000/generate-fairytale';
  if (history !== null) {
    samples = 1;
  }
  const requestData = { user_input, seed, samples, style, history };
  return await sendApiRequest(endpoint, requestData, isFairyTale);
}

// お題生成のエンドポイントにリクエストする関数
export async function fetchThemeData(
  user_input,
  seed,
  style = null,
  history = null
) {
  let samples;
    const endpoint = 'https://app-it-fair2-gycteneha5d3beb9.japanwest-01.azurewebsites.net/generate-theme';
    //  const endpoint = 'http://127.0.0.1:8000/generate-theme';

  console.log("theme generation called");
  if (history !== null) {
    samples = 1;
  }
  const requestData = { user_input, seed, samples, style, history };
  return await sendApiRequest(endpoint, requestData);
}
/*
再生成時のリクエスト例
body: {
    user_input: 'シンデレラのドレスの色を赤に変更する',
    seed: 123456,
    style: anime,
    history: [{'user': 'シンデレラが王子様と舞踏会でダンスする', 'assistant': '(fairy-tale scene...)'}]
}
*/

// localStorageへ画像生成に利用した命令を追加していく関数
export async function saveToHistory(user_input, generate_prompt) {
  // console.log("saveToHistory is called")
  const newHistoryItem = {
    user: user_input,
    assistant: generate_prompt,
  };

  // 既存の履歴を取得、無ければ新たに配列を生成
  const existingHistory = JSON.parse(localStorage.getItem("history")) || [];
  // console.log("existingHistory:", existingHistory)

  // localStorageに2重登録される問題が生じたため、値が重複していたら削除する処理を追加
  const historyIndex = existingHistory.findIndex(
    (item) =>
      item.user === newHistoryItem.user &&
      item.assistant === newHistoryItem.assistant
  );

  if (historyIndex !== -1) {
    existingHistory.splice(historyIndex, 1);
  }

  // 再生成を3回以上連続すると古い命令を消去する
  if (existingHistory.length === 4) {
    existingHistory.splice(1, 1);
  }
  existingHistory.push(newHistoryItem);

  //historyへ履歴を保存する
  // console.log("save to localstorage");
  localStorage.setItem("history", JSON.stringify(existingHistory));
  // console.log("save finished to localstorage");
}

// localStorageを初期化する関数
/*
localStorageは画面遷移や再読み込みをしてもデータが保持されるため、初期化処理が必要です。
localStorageは再生成の際に命令とseed値を保存する用途で利用しています。
童話, お題を選択する画面や、編集作業が完了がされたら実行する必要があります。
*/
export async function initLocalStorage() {
  localStorage.clear();
}

export async function clearRegenerateHistory() {
  let history = JSON.parse(localStorage.getItem("history"));
  history = [history[0]];
  localStorage.setItem("history", JSON.stringify(history));
}